window.xoutCard = function(id, url_token) {
  // show the answer
  var thisCard = document.getElementById('card-link-' + id);
  thisCard.style.display = 'none';

  Rails.ajax({
    url: "/clicks/xout",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token),
    success: function(data) {
      document.location.reload();
    }
  });
}

window.xoutArticle = function(url_token) {
  Rails.ajax({
    url: "/clicks/xout",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token),
    success: function(data) {
      console.log(data);
    }
  });
}

window.saveArticle = function(url_token) {
  Rails.ajax({
    url: "/clicks/save",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token),
    success: function(data) {
      console.log(data);
    }
  });
}

window.saveOnlyArticle = function(url_token) {
  Rails.ajax({
    url: "/clicks/save",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token) + "&toggle=false",
    success: function(data) {
      console.log(data);
    }
  });
}

window.completeArticle = function(url_token, click_type) {
  Rails.ajax({
    url: "/clicks/complete",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token) + "&click_type=" + encodeURIComponent(click_type),
    success: function(data) {
      document.location.reload();
    }
  });
}

window.clickArticle = function(url_token) {
  Rails.ajax({
    url: "/clicks/click",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token),
    success: function(data) {
      console.log(data);
    }
  });
}

window.xoutQuestion = function(id, url_token) {
  // show the answer
  var thisCard = document.getElementById('question_' + id);
  thisCard.style.display = 'none';

  Rails.ajax({
    url: "/clicks/question_click",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token) + "&question_index=" + encodeURIComponent(id) + "&click_type=xout",
    success: function(data) {
      console.log(data);
    }
  });
}

window.showAnswer = function(id) {
    // show the answer
    var myAnswer = document.getElementById('answer_' + id);
    var displaySetting = myAnswer.style.display;
    var url_token = document.getElementById('url_token').innerHTML.trim();

    var answerButton = document.getElementById('answer_button_' + id);
    // change span class to matching-text
    
    var matchingTextSpan = document.getElementById('matching_text_' + id);
    if (matchingTextSpan != null) {
      matchingTextSpan.className = 'matching-text';
    } 

    if (displaySetting == 'none') {
        myAnswer.style.display = 'block';
        answerButton.style.display = 'none';
    }

    Rails.ajax({
      url: "/clicks/question_click",
      type: "POST",
      dataType: 'json',
      data: "url_token=" + encodeURIComponent(url_token) + "&question_index=" + encodeURIComponent(id) + "&click_type=show_answer",
      success: function(data) {
        console.log(data);
      }
    });
}

window.hideAnswer = function(id) {
  // show the answer
  var myAnswer = document.getElementById('answer_' + id);
  var displaySetting = myAnswer.style.display;
  var answerButton = document.getElementById('answer_button_' + id);
  // change span class to matching-text
  
  var matchingTextSpan = document.getElementById('matching_text_' + id);
  if (matchingTextSpan != null) {
    matchingTextSpan.className = '';
  } 

  if (displaySetting == 'block') {
      myAnswer.style.display = 'none';
      answerButton.style.display = 'block';
  }
}

window.saveAnswer = function(id) {
    // get the question
    var url_token = document.getElementById('url_token').innerHTML.trim();
    var reading_set_element = document.getElementById('reading_set_hashid')
    if (reading_set_element !== null){
      var reading_set_hashid = reading_set_element.innerHTML.trim();
    }
    var myQuestion = document.getElementById('question_text_' + id).innerHTML.trim();
    var myAnswer = document.getElementById('answer_form_' + id).value.trim();
    var saveAnswerButton = document.getElementById('save_answer_button_' + id);
    saveAnswerButton.style.display = 'none';

    var thisCard = document.getElementById('question_' + id);
    thisCard.style.display = 'none';

    var data_string = "";
    if (reading_set_element !== null){
      data_string = "question=" + encodeURIComponent(myQuestion) + "&answer=" + encodeURIComponent(myAnswer) + "&reading_set_hashid=" + encodeURIComponent(reading_set_hashid) +  "&url_token=" + encodeURIComponent(url_token)
    }
    else{
      data_string = "question=" + encodeURIComponent(myQuestion) + "&answer=" + encodeURIComponent(myAnswer) +  "&url_token=" + encodeURIComponent(url_token)
    }

    Rails.ajax({
        url: "/cards/create",
        type: "POST",
        dataType: 'json',
        data: data_string,
        success: function(data) {
          console.log(data);
        }
      });

    Rails.ajax({
      url: "/clicks/question_click",
      type: "POST",
      dataType: 'json',
      data: "url_token=" + encodeURIComponent(url_token) + "&question_index=" + encodeURIComponent(id) + "&click_type=save",
      success: function(data) {
        console.log(data);
      }
    });
}

window.clearFreeAnswer = function(id) {
  var free_answer = document.getElementById("free_answer_form_" + id);
  var free_question = document.getElementById("free_question_text_" + id);        
  free_question.value = '';
  free_answer.value = '';
}

window.saveFreeAnswer = function(id) {
  // get the question
  var url_token = document.getElementById('url_token').innerHTML.trim();
  var reading_set_element = document.getElementById('reading_set_hashid')
  if (reading_set_element !== null){
    var reading_set_hashid = reading_set_element.innerHTML.trim();
  }
  var myQuestion = document.getElementById('free_question_text_' + id).value.trim();
  var myAnswer = document.getElementById('free_answer_form_' + id).value.trim();

  var data_string = "";
  if (reading_set_element !== null){
    data_string = "question=" + encodeURIComponent(myQuestion) + "&answer=" + encodeURIComponent(myAnswer) + "&reading_set_hashid=" + encodeURIComponent(reading_set_hashid) +  "&url_token=" + encodeURIComponent(url_token);
  }
  else{
    data_string = "question=" + encodeURIComponent(myQuestion) + "&answer=" + encodeURIComponent(myAnswer) +  "&url_token=" + encodeURIComponent(url_token);
  }
  
  if (myAnswer.length > 0 && myQuestion.length > 0) {
    Rails.ajax({
        url: "/cards/create",
        type: "POST",
        dataType: 'json',
        data: data_string,
        success: function(data) {     
          var free_answer = document.getElementById("free_answer_form_" + id);
          var free_question = document.getElementById("free_question_text_" + id);      
          free_question.value = '';
          free_answer.value = '';
          var save_free_answer_text = document.getElementById("save_free_answer_text_" + id);
          save_free_answer_text.style.display = 'block';
          // set a timeout to hide the save text
          setTimeout(function() {
            save_free_answer_text.style.display = 'none';
          }
          , 2000);
        }
      });
  }
  else {
    var save_free_answer_text = document.getElementById("save_free_answer_text_" + id);
    save_free_answer_text.innerHTML = 'Please add both an answer and a question.';
    save_free_answer_text.style.display = 'block';
    
    // set a timeout to hide the save text
    setTimeout(function() {
      save_free_answer_text.style.display = 'none';
      save_free_answer_text.innerHTML = 'Saved!';
    }
    , 2000);
  }
}

window.showCardAnswer = function(id) {
  // show the answer
  var myAnswer = document.getElementById('card_answer_' + id);
  var myAnswerButton = document.getElementById('card_answer_button_' + id);
  var displaySetting = myAnswer.style.display;
  if (displaySetting == 'none') {
      myAnswer.style.display = 'block';
      myAnswerButton.style.display = 'none';
  }
}

window.labelCardAnswer = function(id, total_cards, card_hashid, response, isModal = false) {
  var thisCard = document.getElementById('card_' + id);
  thisCard.style.display = 'none';
  if (isModal) {
    if (id < total_cards - 1){ 
      var nextCard = document.getElementById('card_' + (id+1));
      nextCard.style.display = 'block';
    }
    else{
      // get the current url and take the second section
      var footer = document.getElementById('closemodalbutton');
      footer.style.display = 'block';
    }
  }

  Rails.ajax({
    url: "/cards/review",
    type: "POST",
    dataType: 'json',
    data: "card_hashid=" + encodeURIComponent(card_hashid) + "&label=" + encodeURIComponent(response),
    success: function(data) {
      console.log(data);
    }
  });
}

window.showTileAnswer = function(id) {
  // show the answer
  var myAnswer = document.getElementById('tile_answer_' + id);
  var myAnswerButton = document.getElementById('tile_answer_button_' + id);
  var displaySetting = myAnswer.style.display;
  if (displaySetting == 'none') {
      myAnswer.style.display = 'block';
      myAnswerButton.style.display = 'none';
  }
}

window.saveTileAnswer = function(position, url_token, question_index = 0) {
    // get the question
    var reading_set_element = document.getElementById('reading_set_hashid')
    if (reading_set_element !== null){
      var reading_set_hashid = reading_set_element.innerHTML.trim();
    }
    else{
      var reading_set_hashid = '';
    }

    var myQuestion = document.getElementById('tile_question_text_' + position).innerHTML.trim();
    var myAnswer = document.getElementById('tile_answer_text_' + position).innerHTML.trim();
    var saveAnswerButton = document.getElementById('tile_save_answer_button_' + position);
    saveAnswerButton.style.display = 'none';

    var data_string = "";
    if (reading_set_element !== null){
      data_string = "question=" + encodeURIComponent(myQuestion) + "&answer=" + encodeURIComponent(myAnswer) + "&reading_set_hashid=" + encodeURIComponent(reading_set_hashid) +  "&url_token=" + encodeURIComponent(url_token) 
    }
    else{
      data_string = "question=" + encodeURIComponent(myQuestion) + "&answer=" + encodeURIComponent(myAnswer) +  "&url_token=" + encodeURIComponent(url_token)
    }
    var thisCard = document.getElementById('tile_question_' +  position);
    thisCard.innerHTML = 'Saved!';

    Rails.ajax({
        url: "/cards/create",
        type: "POST",
        dataType: 'json',
        data: data_string,
        success: function(data) {
          console.log(data);
        }
      });

    Rails.ajax({
      url: "/clicks/question_click",
      type: "POST",
      dataType: 'json',
      data: "url_token=" + encodeURIComponent(url_token) + "&question_index=" + encodeURIComponent(question_index) + "&click_type=save",
      success: function(data) {
        console.log(data);
      }
    });

    Rails.ajax({
      url: "/questions/generate",
      type: "POST",
      dataType: 'json',
      data: "position=" + encodeURIComponent(position) + "&reading_set_hashid=" + encodeURIComponent(reading_set_hashid),
      success: function(data) {
        var thisCard = document.getElementById('tile_question_' + position);
        if (data.remove == true) {
          thisCard.remove()
        }
        else{
          thisCard.innerHTML = data.html;
        }
      }
    });
}

window.xoutTileQuestion = function(position, url_token, question_hashid) {
  var reading_set_element = document.getElementById('reading_set_hashid')
  if (reading_set_element !== null){
    var reading_set_hashid = reading_set_element.innerHTML.trim();
  }
  else{
    var reading_set_hashid = '';
  }
  
  var thisCard = document.getElementById('tile_question_' + position);

  Rails.ajax({
    url: "/clicks/question_click",
    type: "POST",
    dataType: 'json',
    data: "url_token=" + encodeURIComponent(url_token) + "&question_hashid=" + encodeURIComponent(question_hashid) + "&click_type=xout",
    success: function(data) {
      console.log(data);
    }
  });

  Rails.ajax({
    url: "/questions/generate",
    type: "POST",
    dataType: 'json',
    data: "position=" + encodeURIComponent(position) + "&reading_set_hashid=" + encodeURIComponent(reading_set_hashid),
    success: function(data) {
      var thisCard = document.getElementById('tile_question_' + position);
      if (data.remove == true) {
        thisCard.remove()
      }
      else{
        thisCard.innerHTML = data.html;
      }
    }
  });
}

window.selectReadingSet = function(reading_set_hashid) {
  Rails.ajax({
    url: "/readingset/toggle_select",
    type: "POST",
    dataType: 'json',
    data: "reading_set_hashid=" + encodeURIComponent(reading_set_hashid),
    success: function(data) {
      location.reload();
    }
  });
}

window.openModal = function() {
  document.getElementById("backdrop").style.display = "block"
  document.getElementById("exampleModal").style.display = "block"
  document.getElementById("exampleModal").classList.add("show")
}

window.closeModal = function() {
  document.getElementById("backdrop").style.display = "none"
  document.getElementById("exampleModal").style.display = "none"
  document.getElementById("exampleModal").classList.remove("show")
}

window.onclick = function(event) {
  var modal = document.getElementById('exampleModal');
  if (event.target == modal) {
    closeModal()
  }
}

window.collapseQueueRow = function(i){
  document.getElementById("queue-" + i).style.display = "none";
}

window.updateQueueRow = function(i){
  var value = document.getElementById("save-" + i).value;
  if (value == "unsaved"){
    document.getElementById("save-" + i).value = "saved";
    document.getElementById("save-" + i).innerHTML = "&#9733;";
  }
  else{
    document.getElementById("save-" + i).value = "unsaved";
    document.getElementById("save-" + i).innerHTML = "&#9734;";
  }

}